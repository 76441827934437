import { GlobalPromotionEdit } from '../components/GlobalPromotion';

function GlobalPromotionEditRoute() {
  return <GlobalPromotionEdit />;
}

export const Component = GlobalPromotionEditRoute;

export function clientLoader() {
  return null;
}
